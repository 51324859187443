import {
  AppBar,
  Box,
  Container,
  Toolbar,
  Button,
  Stack,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  useTheme,
  useMediaQuery,
  Divider,
  Typography,
  ListItemButton,
} from '@mui/material';
import {
  Menu as MenuIcon,
  Close as CloseIcon,
  ChevronRight as ChevronRightIcon,
  Home as HomeIcon,
  Info as InfoIcon,
  Article as ArticleIcon,
  Mail as MailIcon,
  Login as LoginIcon,
} from '@mui/icons-material';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logoImage from '../assets/logo-horiz.png';

const TopNavigation = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation();

  const navigationItems = [
    { title: 'About Us', path: '/about', icon: <InfoIcon /> },
    { title: 'Blog', path: '/blog', icon: <ArticleIcon /> },
    { title: 'Contact', path: '/contact', icon: <MailIcon /> },
  ];

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const isCurrentPath = (path) => {
    return location.pathname === path;
  };

  console.log('isMobile:', isMobile);

  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{
        bgcolor: 'white',
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
        left: 0,
        right: 0,
      }}
    >
      <Drawer
        anchor="right"
        open={mobileMenuOpen}
        onClose={() => setMobileMenuOpen(false)}
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '320px',
            bgcolor: 'white',
            px: 2,
            height: '100%',
            zIndex: theme.zIndex.appBar + 100,
          }
        }}
        sx={{
          zIndex: theme.zIndex.appBar + 100,
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
          },
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column',
          height: '100%',
          pt: 2,
        }}>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            mb: 2,
          }}>
            <Typography variant="h6" color="primary">
              Menu
            </Typography>
            <IconButton 
              onClick={() => setMobileMenuOpen(false)}
              sx={{ color: 'primary.main' }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider sx={{ mb: 2 }} />
          <List sx={{ flexGrow: 1 }}>
            {navigationItems.map((item) => (
              <ListItem 
                key={item.title} 
                disablePadding
                sx={{ mb: 1 }}
              >
                <ListItemButton
                  component={Link}
                  to={item.path}
                  onClick={() => setMobileMenuOpen(false)}
                  selected={isCurrentPath(item.path)}
                  sx={{
                    borderRadius: 1,
                    '&.Mui-selected': {
                      bgcolor: 'rgba(24, 26, 239, 0.08)',
                      '&:hover': {
                        bgcolor: 'rgba(24, 26, 239, 0.12)',
                      }
                    },
                    '&:hover': {
                      bgcolor: 'rgba(24, 26, 239, 0.04)',
                    }
                  }}
                >
                  <ListItemIcon sx={{ 
                    color: isCurrentPath(item.path) ? 'primary.main' : 'text.secondary',
                    minWidth: 40,
                  }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText 
                    primary={item.title}
                    sx={{
                      color: isCurrentPath(item.path) ? 'primary.main' : 'text.primary',
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Box sx={{ p: 2 }}>
            <Button
              variant="contained"
              fullWidth
              startIcon={<LoginIcon />}
              onClick={() => setMobileMenuOpen(false)}
              sx={{
                bgcolor: 'primary.main',
                color: 'white',
                '&:hover': {
                  bgcolor: 'primary.dark'
                }
              }}
            >
              Get Started
            </Button>
          </Box>
        </Box>
      </Drawer>
      <Container 
        maxWidth={false}
        sx={{
          maxWidth: 'lg',
          px: { xs: 1.5, sm: 3 },
          mx: 'auto',
        }}
      >
        <Toolbar 
          disableGutters
          sx={{ 
            justifyContent: 'space-between', 
            py: 1,
            minHeight: { xs: '56px', sm: '64px' },
          }}
        >
          <Link to="/">
            <Box
              component="img"
              sx={{
                height: 'auto',
                width: { xs: 110, sm: 150 },
                display: 'block',
              }}
              src={logoImage}
              alt="AgriAssure Logo"
            />
          </Link>
          
          {isMobile ? (
            <IconButton 
              onClick={toggleMobileMenu} 
              sx={{ 
                color: 'primary.main',
                p: 1,
              }}
              edge="end"
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <Stack 
              direction="row" 
              spacing={2}
              sx={{ 
                mr: -1
              }}
            >
              {navigationItems.map((item) => (
                <Button
                  key={item.title}
                  component={Link}
                  to={item.path}
                  color="primary"
                  sx={{
                    color: 'primary.main',
                    bgcolor: isCurrentPath(item.path) ? 'rgba(24, 26, 239, 0.08)' : 'transparent',
                    '&:hover': {
                      bgcolor: 'rgba(24, 26, 239, 0.12)'
                    }
                  }}
                >
                  {item.title}
                </Button>
              ))}
              <Button
                variant="contained"
                sx={{
                  bgcolor: 'primary.main',
                  color: 'white',
                  '&:hover': {
                    bgcolor: 'primary.dark'
                  }
                }}
              >
                Get Started
              </Button>
            </Stack>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default TopNavigation;
