import { Box, Container, Grid } from '@mui/material';
import GlobalMapIllustration from './GlobalMapIllustration';
import LocationSelector from './LocationSelector';

const HeroSection = ({ onFlowSelection, selectedFlow }) => {
    return (
        <Box
            sx={{
                background: 'white',
                color: 'primary.main',
                minHeight: '100vh',
                display: 'flex',
                alignItems: 'center',
                pt: { xs: 10, md: 0 },
                pb: { xs: 8, md: 0 },
                width: '100%',
            }}
        >
            <Container
                maxWidth="lg"
                sx={{
                    px: { xs: 2, sm: 3 },
                }}
            >
                <Grid container spacing={{ xs: 3, md: 6 }} alignItems="center">
                    <Grid item xs={12}>
                        <LocationSelector 
                            onFlowSelection={onFlowSelection} 
                            selectedFlow={selectedFlow} 
                        />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default HeroSection;