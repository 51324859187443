import { Box } from '@mui/material';
import TopNavigation from '../components/TopNavigation';
import AboutSection from '../components/AboutSection';
import { useEffect } from 'react';

const AboutPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box sx={{ overflowX: 'hidden' }}>
      <TopNavigation />
      <AboutSection />
    </Box>
  );
};

export default AboutPage;
