import { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Typography
} from '@mui/material';

const VALID_PASSWORD = process.env.REACT_APP_MVP_PASSWORD;

const ProtectedRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(true);

  useEffect(() => {
    const auth = localStorage.getItem('agriassure-auth');
    if (auth === 'true') {
      setIsAuthenticated(true);
      setOpen(false);
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === VALID_PASSWORD) {
      setIsAuthenticated(true);
      localStorage.setItem('agriassure-auth', 'true');
      setOpen(false);
    } else {
      setError(true);
    }
  };

  if (isAuthenticated) {
    return children;
  }

  return (
    <>
      <Dialog open={open} maxWidth="xs" fullWidth>
        <DialogTitle sx={{ textAlign: 'center' }}>
          Welcome to AgriAssure
        </DialogTitle>
        <DialogContent>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
            <TextField
              fullWidth
              type="password"
              label="Enter Password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setError(false);
              }}
              error={error}
              helperText={error && "Incorrect password"}
              sx={{ mb: 3 }}
            />
            <Button
              fullWidth
              variant="contained"
              type="submit"
              sx={{ mb: 2 }}
            >
              Access Platform
            </Button>
            <Typography variant="body2" color="text.secondary" align="center">
              Please enter the password to access the platform
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ProtectedRoute;
