import { Box, Container, Typography, Grid, Paper, useTheme } from '@mui/material';
import {
  AccountCircle,
  Upload,
  Timeline,
  VerifiedUser
} from '@mui/icons-material';

const steps = [
  {
    icon: <AccountCircle sx={{ fontSize: 50 }} />,
    title: 'Sign Up',
    description: 'Create an account and tell us about your business.',
    color: '#2196f3' // blue
  },
  {
    icon: <Upload sx={{ fontSize: 50 }} />,
    title: 'Upload Documents',
    description: 'Share the required details for assessment.',
    color: '#4caf50' // green
  },
  {
    icon: <Timeline sx={{ fontSize: 50 }} />,
    title: 'Track Progress',
    description: 'See compliance tasks and deadlines on your dashboard.',
    color: '#ff9800' // orange
  },
  {
    icon: <VerifiedUser sx={{ fontSize: 50 }} />,
    title: 'Achieve Compliance',
    description: 'Receive tailored guidance and reports.',
    color: '#9c27b0' // purple
  }
];

const HowItWorksSection = () => {
  const theme = useTheme();

  return (
    <Box 
      sx={{ 
        pt: 10,
        background: 'white',
        position: 'relative'
      }}
    >
      <Container maxWidth="lg">
        <Typography variant="h2" align="center" gutterBottom>
          How AgriAssure Works
        </Typography>
        <Typography
          variant="h6"
          align="center"
          color="text.secondary"
          sx={{ mb: 8 }}
        >
          Four simple steps to achieve compliance
        </Typography>

        <Grid container spacing={4} justifyContent="center">
          {steps.map((step, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Paper
                elevation={0}
                sx={{
                  p: 3,
                  height: '100%',
                  textAlign: 'center',
                  position: 'relative',
                  background: 'transparent',
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: '40px',
                    right: '-50%',
                    width: '100%',
                    height: '2px',
                    background: `${step.color}`,
                    display: {
                      xs: 'none',
                      md: index === steps.length - 1 ? 'none' : 'block'
                    }
                  }
                }}
              >
                <Box
                  sx={{
                    width: 80,
                    height: 80,
                    borderRadius: '50%',
                    background: `${step.color}15`,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '0 auto 20px',
                    position: 'relative',
                    '&::after': {
                      content: `"${index + 1}"`,
                      position: 'absolute',
                      top: -10,
                      right: -10,
                      width: 30,
                      height: 30,
                      borderRadius: '50%',
                      background: step.color,
                      color: 'white',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '16px',
                      fontWeight: 'bold'
                    }
                  }}
                >
                  <Box sx={{ color: step.color }}>
                    {step.icon}
                  </Box>
                </Box>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ color: step.color }}
                >
                  {step.title}
                </Typography>
                <Typography color="text.secondary">
                  {step.description}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default HowItWorksSection;
