import {
  Box,
  Container,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  Stack,
  AppBar,
  Toolbar,
  useTheme,
  useMediaQuery,
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
  TextField,
  alpha
} from '@mui/material';
import {
  Security,
  Speed,
  Analytics,
  CloudDone,
  CheckCircle,
  Menu as MenuIcon,
  DocumentScanner,
  Gavel,
  FolderOpen,
  SupportAgent,
  NotificationsActive,
  ChevronLeft,
  ChevronRight
} from '@mui/icons-material';
import { useState, useRef, useEffect } from 'react';
import DashboardIllustration from '../components/DashboardIllustration';
import logoImage from '../assets/logo-horiz.png';
import { Link } from 'react-router-dom';
import TopNavigation from '../components/TopNavigation';
import BenefitsSection from '../components/BenefitsSection';
import HowItWorksSection from '../components/HowItWorksSection';
import Footer from '../components/Footer';
import AnimatedSection from '../components/AnimatedSection';
import CheerleadersSection from '../components/CheerleadersSection';
import HeroSection from '../components/HeroSection';

const LandingPage = ({ defaultSection = 'home' }) => {
  const theme = useTheme();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const homeRef = useRef(null);
  const contactRef = useRef(null);
  const carouselRef = useRef(null);
  const [userFlow, setUserFlow] = useState('canada');

  const handleFlowSelection = (flow) => {
    setUserFlow(flow);
    // Add navigation logic here
  };

  useEffect(() => {
    // Only scroll if defaultSection is specified and not 'home'
    if (defaultSection === 'contact') {
      contactRef.current?.scrollIntoView({ behavior: 'smooth' });
    } else {
      // For home or any other section, scroll to top
      window.scrollTo(0, 0);
    }
  }, [defaultSection]);

  useEffect(() => {
    const carousel = carouselRef.current;
    if (!carousel) return;

    // Set initial scroll position
    carousel.scrollLeft = 0;
  }, []);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const features = [
    {
      icon: <DocumentScanner sx={{ fontSize: 40 }} />,
      title: 'Compliance Task Tracker',
      description: 'Stay on top of your compliance tasks with our intuitive tracking system'
    },
    {
      icon: <Gavel sx={{ fontSize: 40 }} />,
      title: 'Regulation Overview Panel',
      description: 'Clear, up-to-date summaries of relevant agricultural regulations'
    },
    {
      icon: <FolderOpen sx={{ fontSize: 40 }} />,
      title: 'Document Management System',
      description: 'Centralized storage and organization of all compliance-related documents'
    },
    {
      icon: <SupportAgent sx={{ fontSize: 40 }} />,
      title: 'Expert Support for Canadian Regulations',
      description: 'Access to specialists well-versed in Canadian agricultural compliance'
    },
    {
      icon: <NotificationsActive sx={{ fontSize: 40 }} />,
      title: 'Notifications and Deadline Alerts',
      description: 'Never miss important compliance deadlines with automated reminders'
    }
  ];

  const handlePrevClick = () => {
    const carousel = carouselRef.current;
    if (carousel) {
      const scrollAmount = 300;
      carousel.scrollLeft = Math.max(0, carousel.scrollLeft - scrollAmount);
    }
  };

  const handleNextClick = () => {
    const carousel = carouselRef.current;
    if (carousel) {
      const scrollAmount = 300;
      const maxScroll = carousel.scrollWidth - carousel.clientWidth;
      carousel.scrollLeft = Math.min(maxScroll, carousel.scrollLeft + scrollAmount);
    }
  };

  return (
    <Box sx={{ overflowX: 'hidden' }}>
      <TopNavigation />
      {/* Hero Section */}
      <AnimatedSection>
        <Box
          ref={homeRef}
          sx={{
            background: 'white',
            color: 'primary.main',
            minHeight: '100vh',
            display: 'flex',
            alignItems: 'center',
            pt: { xs: 10, md: 0 },
            pb: { xs: 8, md: 0 },
            width: '100%',
          }}
        >
          <Container
            maxWidth="lg"
            sx={{
              px: { xs: 2, sm: 3 },
            }}
          >
            <Grid container spacing={{ xs: 3, md: 6 }} alignItems="center">
              <Grid item xs={12} md={7}>
                <Card
                  elevation={0}
                  sx={{
                    p: 4,
                    background: 'rgba(255, 255, 255, 0.8)',
                    backdropFilter: 'blur(10px)',
                    borderRadius: 2,
                    border: '1px solid rgba(255, 255, 255, 0.2)'
                  }}
                >
                  <Typography variant="h1" gutterBottom sx={{ fontSize: '2.5rem' }}>
                    Simplify Your Agri-Food Compliance. Empower Your Business.
                  </Typography>
                  <Typography variant="h6" sx={{ mb: 4, opacity: 0.9, color: 'text.secondary' }}>
                    AgriAssure streamlines regulatory compliance for Canada's domestic agriculture and food processing industry. Built for non-Canadians seeking to enter the Canadian market and Domestic Canadian Companies seeking to understand their requirements or make compliance faster, easier and cost effective.
                  </Typography>
                  <Stack direction="row" spacing={2}>
                    <Button
                      variant="contained"
                      size="large"
                      sx={{
                        bgcolor: 'primary.main',
                        color: 'white'
                      }}
                    >
                      Get Started
                    </Button>
                    <Button
                      variant="outlined"
                      size="large"
                      sx={{
                        color: 'primary.main',
                        borderColor: 'primary.main'
                      }}
                    >
                      Learn More
                    </Button>
                  </Stack>
                </Card>
              </Grid>
              <Grid item xs={12} md={5}>
                <DashboardIllustration />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </AnimatedSection>

      <Box
        sx={{
          width: '100%',
          bgcolor: 'background.paper',
          py: 2
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              width: '100%',
              height: '1px',
              bgcolor: theme => alpha(theme.palette.divider, 0.1)
            }}
          />
        </Container>
      </Box>

      {/* Features Section */}
      <AnimatedSection>
        <Container
          maxWidth="lg"
          sx={{
            py: 10,
            px: { xs: 2, sm: 3 },
          }}
        >
          <Typography variant="h2" align="center" gutterBottom>
            Why Choose Us?
          </Typography>
          <Typography
            variant="h6"
            align="center"
            color="text.secondary"
            sx={{ mb: 8 }}
          >
            Here's What We Offer
          </Typography>

          {/* Mobile View - Vertical Stack */}
          <Box
            sx={{
              display: { xs: 'flex', md: 'none' },
              flexDirection: 'column',
              gap: 2,
            }}
          >
            {features.map((feature, index) => (
              <Card
                key={index}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  p: 3,
                  borderRadius: 2,
                  bgcolor: 'background.paper',
                  boxShadow: theme => theme.shadows[1],
                  '&:hover': {
                    boxShadow: theme => theme.shadows[4],
                  },
                }}
              >
                <Box sx={{ color: 'primary.main', mb: 2 }}>
                  {feature.icon}
                </Box>
                <Typography variant="h6" gutterBottom align="center">
                  {feature.title}
                </Typography>
                <Typography color="text.secondary" align="center">
                  {feature.description}
                </Typography>
              </Card>
            ))}
          </Box>

          {/* Desktop View - Horizontal Scroll */}
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              overflow: 'hidden',
              py: 4,
              display: { xs: 'none', md: 'block' },
            }}
          >
            {/* Left Gradient Overlay */}
            <Box
              sx={{
                position: 'absolute',
                left: 0,
                top: 0,
                bottom: 0,
                width: '100px',
                background: 'linear-gradient(to right, white 0%, transparent 100%)',
                zIndex: 2,
                pointerEvents: 'none',
              }}
            />

            {/* Right Gradient Overlay */}
            <Box
              sx={{
                position: 'absolute',
                right: 0,
                top: 0,
                bottom: 0,
                width: '100px',
                background: 'linear-gradient(to left, white 0%, transparent 100%)',
                zIndex: 2,
                pointerEvents: 'none',
              }}
            />

            <Box
              sx={{
                display: 'flex',
                gap: 2,
                animation: 'scroll 40s linear infinite',
                '@keyframes scroll': {
                  '0%': {
                    transform: 'translateX(0)',
                  },
                  '100%': {
                    transform: 'translateX(-50%)',
                  },
                },
                '&:hover': {
                  animationPlayState: 'paused',
                },
              }}
            >
              {[...features, ...features].map((feature, index) => (
                <Card
                  key={index}
                  sx={{
                    minWidth: 280,
                    height: 250,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    p: 3,
                    borderRadius: 2,
                    bgcolor: 'background.paper',
                    boxShadow: theme => theme.shadows[1],
                    transition: theme =>
                      theme.transitions.create(['box-shadow'], {
                        duration: theme.transitions.duration.short,
                      }),
                    '&:hover': {
                      boxShadow: theme => theme.shadows[4],
                    },
                  }}
                >
                  <Box sx={{ color: 'primary.main', mb: 2 }}>
                    {feature.icon}
                  </Box>
                  <Typography variant="h6" gutterBottom align="center">
                    {feature.title}
                  </Typography>
                  <Typography color="text.secondary" align="center">
                    {feature.description}
                  </Typography>
                </Card>
              ))}
            </Box>
          </Box>
        </Container>
      </AnimatedSection>

      {/* Benefits Section */}
      <AnimatedSection>
        <BenefitsSection />
      </AnimatedSection>

      {/* How It Works Section */}
      <AnimatedSection>
        <HowItWorksSection />
      </AnimatedSection>

      <AnimatedSection>
        <HeroSection onFlowSelection={handleFlowSelection} selectedFlow={userFlow} />
      </AnimatedSection>

      {/* Contact Form */}
      <AnimatedSection>
        <Box sx={{ bgcolor: '#F8F9FA' }}>
          <Container
            ref={contactRef}
            maxWidth="md"
            sx={{
              py: 10,
              px: { xs: 2, sm: 3 },
            }}
          >
            <Card
              elevation={2}
              sx={{
                p: 4,
                borderRadius: 2,
                bgcolor: 'background.paper',
              }}
            >
              <Typography variant="h2" align="center" gutterBottom>
                Get in Touch
              </Typography>
              <Typography
                variant="h6"
                align="center"
                color="text.secondary"
                sx={{ mb: 8 }}
              >
                Have questions? We'd love to hear from you.
              </Typography>
              <Box component="form" noValidate sx={{ mt: 3 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      label="First Name"
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      label="Last Name"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      label="Email Address"
                      type="email"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      label="Message"
                      multiline
                      rows={4}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      size="large"
                    >
                      Send Message
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Container>
        </Box>
      </AnimatedSection>

      <CheerleadersSection />

      {/* Call to Action */}
      <Box sx={{
        bgcolor: 'white',
        color: 'primary.main',
        py: 10,
        width: '100%',
      }}>
        <Container
          maxWidth="md"
          sx={{
            textAlign: 'center',
            px: { xs: 2, sm: 3 },
          }}
        >
          <Typography variant="h2" gutterBottom>
            Ready to Get Started?
          </Typography>
          <Typography sx={{ mb: 4, color: 'text.secondary' }}>
            Join thousands of agricultural businesses managing their compliance with AgriAssure
          </Typography>
          <Button
            variant="contained"
            size="large"
            sx={{
              bgcolor: 'primary.main',
              color: 'white',
              '&:hover': {
                bgcolor: 'primary.dark'
              }
            }}
          >
            Get Started
          </Button>
        </Container>
      </Box>

      <Footer />
    </Box>
  );
};

export default LandingPage;