import {
  Box,
  Container,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  Stack,
  AppBar,
  Toolbar,
  useTheme,
  useMediaQuery,
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { useState } from 'react';
import logoImage from '../assets/logo-horiz.png';
import TopNavigation from '../components/TopNavigation';

const NewsPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const newsItems = [
    {
      title: 'AgriAssure Launches New Compliance Tool',
      date: 'March 15, 2024',
      description: 'Introducing our latest feature to help agricultural businesses streamline their compliance processes.',
      category: 'Product Update'
    },
    {
      title: 'Industry Recognition: AgriAssure Wins Innovation Award',
      date: 'March 10, 2024',
      description: 'AgriAssure has been recognized for its innovative approach to agricultural compliance management.',
      category: 'Company News'
    },
    {
      title: 'New Partnership Announcement',
      date: 'March 5, 2024',
      description: 'AgriAssure partners with leading agricultural organizations to enhance compliance solutions.',
      category: 'Partnership'
    },
    // Add more news items as needed
  ];

  return (
    <Box>
      <TopNavigation />
      {/* News Content */}
      <Box
        sx={{
          pt: 12,
          pb: 8,
          bgcolor: 'white',
        }}
      >
        <Container maxWidth="lg">
          <Typography variant="h2" align="center" gutterBottom>
            Latest Blog Posts
          </Typography>
          <Typography
            variant="h6"
            align="center"
            color="text.secondary"
            sx={{ mb: 8 }}
          >
            Stay updated with the latest developments at AgriAssure
          </Typography>

          <Grid container spacing={4}>
            {newsItems.map((item, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Card
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    '&:hover': {
                      transform: 'translateY(-5px)',
                      transition: 'transform 0.3s ease-in-out'
                    }
                  }}
                >
                  <CardContent>
                    <Typography
                      variant="caption"
                      color="primary"
                      sx={{ mb: 1, display: 'block' }}
                    >
                      {item.category}
                    </Typography>
                    <Typography variant="h5" component="h2" gutterBottom>
                      {item.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" paragraph>
                      {item.description}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {item.date}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default NewsPage; 