// src/components/LocationSelector.js
import { Typography, Stack, Grid, Box } from '@mui/material';
import { PublicOutlined, LocationOnOutlined } from '@mui/icons-material';
import LocationCard from './LocationCard';
import { useNavigate } from 'react-router-dom';
import CanadaMap from './CanadaMap';
import WorldMap from './WorldMap';

const LocationSelector = () => {
  const navigate = useNavigate();

  const locations = [
    {
      title: "Canadian Companies",
      description: "Streamline your domestic compliance process and stay up-to-date with Canadian agricultural regulations.",
      icon: <LocationOnOutlined sx={{ fontSize: 40 }} />,
      benefits: [
        "Simplified compliance tracking",
        "Real-time regulatory updates",
        "Document management system",
        "Canadian expert support"
      ],
      path: "/canadian-business",
      color: "#4caf50",
      map: <CanadaMap />
    },
    {
      title: "International Companies",
      description: "Navigate the Canadian market entry process with confidence and ensure compliance with local regulations.",
      icon: <PublicOutlined sx={{ fontSize: 40 }} />,
      benefits: [
        "Market entry guidance",
        "Import requirements",
        "Compliance preparation",
        "Local partnership support"
      ],
      path: "/international-business",
      color: "#2196f3",
      map: <WorldMap />
    }
  ];

  return (
    <>
      <Grid container spacing={4}>
        {locations.map((location, index) => (
          <Grid item xs={12} md={6} key={index}>
            <LocationCard 
              {...location}
              onClick={() => navigate(location.path)}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default LocationSelector;