import { Box } from '@mui/material';
import canadaMap from '../assets/illustrations/canada-map.png';

const CanadaMap = () => {
    return (
        <Box
            component="img"
            src={canadaMap}
            alt="Canada Map"
            sx={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                position: 'absolute',
                top: 0,
                left: 0,
            }}
        />
    );
};

export default CanadaMap;
