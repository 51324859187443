// src/components/illustrations/WorldMap.js
import { Box } from '@mui/material';
import worldMap from '../assets/illustrations/world-map.png';

const WorldMap = () => {
    return (
        <Box
            component="img"
            src={worldMap}
            alt="World Map"
            sx={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                position: 'absolute',
                top: 0,
                left: 0,
            }}
        />
    );
};

export default WorldMap;