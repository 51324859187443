import { Box, Container, Typography, Card, CardContent } from '@mui/material';
import AnimatedSection from './AnimatedSection';
import catalyzerLogo from '../assets/catalyzer.png';
import innovateLogo from '../assets/innovate.png';

const brandLogos = [
    {
        name: 'Alberta Catalyzer',
        imageUrl: catalyzerLogo,
        alt: 'Alberta Catalyzer Logo',
        description: 'Through their partnership with Alberta Catalyzer, AgriAssure is transforming agricultural compliance in Canada with innovative technology solutions. Alberta Catalyzer offers merit-based, pre-accelerator programs for early-stage tech entrepreneurs in Alberta.'
    },
    {
        name: 'Innovate Calgary',
        imageUrl: innovateLogo,
        alt: 'Innovate Calgary Logo',
        description: 'As the innovation hub of the University of Calgary, Innovate Calgary provides the programs, tools, and expert support needed to transform great ideas into successful ventures. AgriAssure is simplifying agricultural compliance with support from Innovate Calgary.'
    }
];

const CheerleadersSection = () => {
    return (
        <Box sx={{ py: 10, bgcolor: 'white' }}>
            <Container maxWidth='lg'>
                <AnimatedSection>
                    <Typography variant='h2' align='center' gutterBottom sx={{ mb: 6 }}>
                        Our Cheerleaders
                    </Typography>

                    {/* Logo Grid */}
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: { xs: '1fr', md: 'repeat(2, 1fr)' },
                            gap: 4,
                            mt: 4
                        }}
                    >
                        {brandLogos.map((brand, index) => (
                            <Card
                                key={index}
                                sx={{
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    p: 3,
                                    boxShadow: 2,
                                    '&:hover': {
                                        boxShadow: 4
                                    }
                                }}
                            >
                                <Box
                                    component='img'
                                    src={brand.imageUrl}
                                    alt={brand.alt}
                                    sx={{
                                        maxWidth: '200px',
                                        width: '100%',
                                        height: 'auto',
                                        alignSelf: 'center',
                                        mb: 3
                                    }}
                                />
                                <CardContent 
                                    sx={{ 
                                        flexGrow: 1, 
                                        p: 0,
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Typography variant='body1' color="text.secondary">
                                        {brand.description}
                                    </Typography>
                                </CardContent>
                            </Card>
                        ))}
                    </Box>
                </AnimatedSection>
            </Container>
        </Box>
    );
};

export default CheerleadersSection;