import { Box, Container, Typography, Grid, Card, CardContent } from '@mui/material';
import {
  TaskAlt,
  FactCheck,
  CloudUpload,
  Groups,
  Campaign
} from '@mui/icons-material';
import AnimatedSection from './AnimatedSection';

const benefits = [
  {
    icon: <TaskAlt sx={{ fontSize: 40 }} />,
    title: 'Compliance Task Tracker',
    description: 'Efficiently manage and track all your compliance tasks in one place, ensuring nothing falls through the cracks.'
  },
  {
    icon: <FactCheck sx={{ fontSize: 40 }} />,
    title: 'Regulation Overview Panel',
    description: 'Stay informed with easy-to-understand summaries of complex agricultural regulations and requirements.'
  },
  {
    icon: <CloudUpload sx={{ fontSize: 40 }} />,
    title: 'Document Management System',
    description: 'Securely store, organize, and access all your compliance documents with our cloud-based solution.'
  },
  {
    icon: <Groups sx={{ fontSize: 40 }} />,
    title: 'Expert Support for Canadian Regulations',
    description: 'Get guidance from our team of experts who understand Canadian agricultural compliance requirements.'
  },
  {
    icon: <Campaign sx={{ fontSize: 40 }} />,
    title: 'Notifications and Deadline Alerts',
    description: 'Stay ahead with timely reminders and alerts for upcoming compliance deadlines and requirements.'
  }
];

const BenefitsSection = () => {
  return (
    <Box sx={{ bgcolor: 'grey.50', py: 10, width: '100%' }}>
      <Container maxWidth="lg" sx={{ px: { xs: 2, sm: 3 } }}>
        <AnimatedSection delay={0.2}>
          <Typography variant="h2" align="center" gutterBottom>
            Benefits For Your Business
          </Typography>
          <Typography
            variant="h6"
            align="center"
            color="text.secondary"
            sx={{ mb: 8 }}
          >
            Transform your compliance management with our comprehensive solutions
          </Typography>
        </AnimatedSection>

        <Grid container spacing={4}>
          {benefits.map((benefit, index) => (
            <Grid 
              item 
              xs={12} 
              md={6} 
              key={index}
              sx={{ display: 'flex' }}
            >
              <AnimatedSection 
                delay={0.3 + index * 0.1} 
                direction={index % 2 === 0 ? 'left' : 'right'}
                sx={{ width: '100%' }}
              >
                <Card
                  sx={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'flex-start',
                    p: 2,
                    '&:hover': {
                      transform: 'translateY(-5px)',
                      transition: 'transform 0.3s ease-in-out'
                    }
                  }}
                >
                  <Box sx={{ color: 'primary.main', mr: 2, pt: 1 }}>
                    {benefit.icon}
                  </Box>
                  <CardContent sx={{ p: 1 }}>
                    <Typography variant="h6" gutterBottom>
                      {benefit.title}
                    </Typography>
                    <Typography color="text.secondary">
                      {benefit.description}
                    </Typography>
                  </CardContent>
                </Card>
              </AnimatedSection>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default BenefitsSection;
