import {
    Box,
    Container,
    Typography,
    Grid,
    Card,
    Stack,
    Button,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useEffect, useRef } from 'react';

const AboutSection = () => {
    const features = [
        'Regulation Overview Panels for clear guidance',
        'Task Trackers to keep you on schedule',
        'Document Management Systems for seamless organization',
        'Expert Support to guide you every step of the way',
        'Importer of Record Services to support on-going compliance with Canadian Food Safety Requirements post launch of your product in Canada'
    ];

    const whyChooseUs = [
        {
            title: 'Tailored Solutions',
            description: "AgriAssure is designed specifically for the unique challenges of Canada's domestic market."
        },
        {
            title: 'User-Friendly Tools',
            description: 'Our intuitive platform makes compliance accessible for everyone, regardless of technical expertise.'
        },
        {
            title: 'Ongoing Support',
            description: "From real-time updates to personalized guidance, we're with you every step of the way."
        }
    ];

    const animatedTextRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('animate');
                    }
                });
            },
            { threshold: 0.1 } // Trigger when at least 10% of the element is visible
        );

        if (animatedTextRef.current) {
            observer.observe(animatedTextRef.current);
        }

        return () => {
            if (animatedTextRef.current) {
                observer.unobserve(animatedTextRef.current);
            }
        };
    }, []);

    return (
        <Box sx={{
            minHeight: '100vh',
            display: 'flex',
            alignItems: 'center',
            background: 'linear-gradient(180deg, #ffffff 0%, #f8f9fa 100%)',
        }}>
            <Container maxWidth="lg">
                <Stack spacing={10}>
                    {/* Mission Statement */}
                    <Box sx={{
                        textAlign: 'center',
                        minHeight: '100vh',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mb: 8
                    }}>
                        <Typography
                            variant="h2"
                            sx={{
                                mb: 4,
                                fontSize: { xs: '2.5rem', md: '3.5rem' },
                            }}
                        >
                            <Box component="span">About </Box>
                            <Box
                                component="span"
                                sx={{
                                    background: 'linear-gradient(180deg, #11c88a 0%, #098a9b 50%, #181aef 100%)',
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent',
                                    backgroundClip: 'text',
                                    color: 'transparent',
                                    fontWeight: 800,
                                }}
                            >
                                AgriAssure
                            </Box>
                        </Typography>
                        <Typography
                            variant="h5"
                            sx={{
                                mb: 6,
                                maxWidth: '800px',
                                mx: 'auto',
                            }}
                        >
                            At AgriAssure, we are driven by a single mission: to empower Canada's domestic agriculture and food processing industry with the tools and support needed to navigate the complexities of regulatory compliance.
                        </Typography>

                        <Typography
                            ref={animatedTextRef}
                            variant="body1"
                            sx={{
                                maxWidth: '800px',
                                mx: 'auto',
                                color: 'text.secondary',
                                opacity: 0,
                                transform: 'translateY(20px)',
                                '&.animate': {
                                    animation: 'fadeInUp 1.5s ease-out forwards',
                                },
                                '@keyframes fadeInUp': {
                                    '0%': {
                                        opacity: 0,
                                        transform: 'translateY(20px)'
                                    },
                                    '100%': {
                                        opacity: 1,
                                        transform: 'translateY(0)'
                                    }
                                }
                            }}
                        >
                            We understand that compliance is not just about meeting standards it's about building trust, ensuring safety, and unlocking opportunities for growth. However, we also know how challenging it can be to stay ahead of constantly evolving regulations. That's why we created AgriAssure, a platform designed to make compliance simple, efficient, and accessible for businesses of all sizes.
                        </Typography>
                    </Box>

                    <Box sx={{ mb: 8 }}>
                        <Divider sx={{ 
                            borderColor: 'rgba(0, 0, 0, 0.1)',
                            width: '100%'
                        }} />
                    </Box>

                    {/* Who We Are */}
                    <Box>
                        <Typography
                            variant="h4"
                            sx={{
                                position: 'relative',
                                mb: 4,
                                '&::after': {
                                    content: '""',
                                    position: 'absolute',
                                    bottom: '-10px',
                                    left: 0,
                                    width: '60px',
                                    height: '4px',
                                    background: 'linear-gradient(90deg, #181aef 0%, #11c88a 100%)',
                                    borderRadius: '2px',
                                }
                            }}
                        >
                            Who We Are
                        </Typography>
                        <Typography
                            paragraph
                            sx={{
                                mb: 3,
                                color: 'text.secondary'
                            }}
                        >
                            We are a team of regulatory experts, tech enthusiasts, and passionate advocates for Canada's agriculture and food sectors. Combining decades of industry knowledge with innovative technology, we've built a platform that bridges the gap between compliance requirements and your business goals.
                        </Typography>
                        <Typography
                            paragraph
                            sx={{
                                mb: 8,
                                color: 'text.secondary'
                            }}
                        >
                            At AgriAssure, we believe that no business should be held back by regulatory hurdles. Whether you're a small producer or a large food processor, we're here to simplify your journey and help you thrive.
                        </Typography>
                    </Box>

                    {/* What We Do Card */}
                    <Card sx={{
                        p: 4,
                        boxShadow: '0 8px 24px rgba(0,0,0,0.1)',
                        borderRadius: 2,
                    }}>
                        <Typography
                            variant="h4"
                            sx={{
                                position: 'relative',
                                mb: 4,
                                '&::after': {
                                    content: '""',
                                    position: 'absolute',
                                    bottom: '-10px',
                                    left: 0,
                                    width: '60px',
                                    height: '4px',
                                    background: 'linear-gradient(90deg, #181aef 0%, #11c88a 100%)',
                                    borderRadius: '2px',
                                }
                            }}
                        >
                            What We Do
                        </Typography>
                        <Typography
                            paragraph
                            sx={{
                                mb: 3,
                                color: 'text.secondary'
                            }}
                        >
                            AgriAssure provides an all-in-one compliance management solution tailored specifically for Canada's domestic regulations. With features like:
                        </Typography>
                        <List sx={{
                            '& .MuiListItem-root': {
                                py: 2,
                                '&:hover': {
                                    bgcolor: 'rgba(17, 200, 138, 0.05)',
                                    borderRadius: 1,
                                    transition: 'all 0.3s ease',
                                }
                            }
                        }}>
                            {features.map((feature, index) => (
                                <ListItem key={index}>
                                    <ListItemIcon>
                                        <CheckCircleOutlineIcon sx={{
                                            color: '#11c88a',
                                            fontSize: '1.5rem',
                                            filter: 'drop-shadow(0 2px 2px rgba(0,0,0,0.1))'
                                        }} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={feature}
                                        sx={{
                                            '& .MuiTypography-root': {
                                                fontSize: '1.1rem',
                                            }
                                        }}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </Card>

                    {/* Vision */}
                    <Typography
                        variant="h4"
                        sx={{
                            position: 'relative',
                            '&::after': {
                                content: '""',
                                position: 'absolute',
                                bottom: '-10px',
                                left: 0,
                                width: '60px',
                                height: '4px',
                                background: 'linear-gradient(90deg, #181aef 0%, #11c88a 100%)',
                                borderRadius: '2px',
                            }
                        }}
                    >
                        Our Vision
                    </Typography>
                    <Typography
                        paragraph
                        sx={{
                            mb: 8,
                            color: 'text.secondary'
                        }}
                    >
                        We envision a future where regulatory compliance is no longer a burden but a seamless part of every business's operations. By combining cutting-edge technology with human expertise, we aim to make compliance not just manageable, but a source of confidence and opportunity for every Canadian agricultural producer and food processor.
                    </Typography>

                    {/* Impact Section */}
                    <Card sx={{ p: 4, mb: 8, textAlign: 'center' }}>
                        <Typography variant="h4" gutterBottom color="primary.main">
                            Our Impact
                        </Typography>
                        <Typography variant="h6">
                            "We facilitate Safe Food for Canadians by simplifying regulatory compliance with food safety regulations to eliminate food induced illnesses"
                        </Typography>
                    </Card>

                    {/* Why Choose Us */}
                    <Typography
                        variant="h4"
                        sx={{
                            position: 'relative',
                            '&::after': {
                                content: '""',
                                position: 'absolute',
                                bottom: '-10px',
                                left: 0,
                                width: '60px',
                                height: '4px',
                                background: 'linear-gradient(90deg, #181aef 0%, #11c88a 100%)',
                                borderRadius: '2px',
                            }
                        }}
                    >
                        Why Choose Us?
                    </Typography>
                    <Grid container spacing={4} sx={{ mb: 8 }}>
                        {whyChooseUs.map((item, index) => (
                            <Grid item xs={12} md={4} key={index}>
                                <Card sx={{
                                    p: 4,
                                    height: '100%',
                                    transition: 'all 0.3s ease',
                                    '&:hover': {
                                        transform: 'translateY(-8px)',
                                        boxShadow: '0 12px 24px rgba(0,0,0,0.1)',
                                    },
                                    borderRadius: 2,
                                    background: 'linear-gradient(145deg, #ffffff 0%, #f8f9fa 100%)',
                                    border: '1px solid rgba(9, 138, 155, 0.1)'
                                }}>
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                        sx={{
                                            fontWeight: 600,
                                            mb: 2,
                                        }}
                                    >
                                        {item.title}
                                    </Typography>
                                    <Typography color="text.secondary">
                                        {item.description}
                                    </Typography>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>

                    {/* Call to Action */}
                    <Card sx={{
                        p: { xs: 4, md: 8 },
                        textAlign: 'center',
                        background: 'linear-gradient(135deg, #181aef 0%, #098a9b 100%)',
                        color: 'white',
                        borderRadius: 3,
                        boxShadow: '0 20px 40px rgba(24, 26, 239, 0.2)',
                    }}>
                        <Typography
                            variant="h4"
                            gutterBottom
                            sx={{
                                fontWeight: 700,
                                mb: 3,
                                color: 'white',
                            }}
                        >
                            Join the AgriAssure Family
                        </Typography>
                        <Typography
                            paragraph
                            sx={{
                                fontSize: '1.1rem',
                                mb: 4,
                                maxWidth: '800px',
                                mx: 'auto',
                            }}
                        >
                            Compliance doesn't have to be complicated. At AgriAssure, we're committed to helping businesses like yours succeed by turning regulatory challenges into opportunities for growth. Let us help you build a stronger, more compliant future for your business.
                        </Typography>
                        <Button
                            variant="contained"
                            size="large"
                            sx={{
                                bgcolor: '#11c88a',
                                px: 6,
                                py: 1.5,
                                fontSize: '1.1rem',
                                fontWeight: 600,
                                borderRadius: '30px',
                                boxShadow: '0 4px 14px rgba(17, 200, 138, 0.4)',
                                '&:hover': {
                                    bgcolor: '#098a9b',
                                    transform: 'translateY(-2px)',
                                    boxShadow: '0 6px 20px rgba(17, 200, 138, 0.6)',
                                },
                                transition: 'all 0.3s ease',
                            }}
                        >
                            Contact us today to learn more or get started!
                        </Button>
                    </Card>
                </Stack>
            </Container>
        </Box>
    );
};

export default AboutSection;
