import { Card, CardContent, Typography, Button, Box, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { CheckCircleOutline } from '@mui/icons-material';

const LocationCard = ({ title, description, icon, benefits, onClick, color, map }) => {
    return (
        <Card
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                '&:hover': {
                    transform: 'translateY(-8px)',
                    boxShadow: 8,
                },
                border: `1px solid ${color}15`,
            }}
        >
            <Box
                sx={{
                    position: 'relative',
                    height: 200,
                    overflow: 'hidden',
                    borderBottom: `1px solid ${color}15`,
                }}
            >
                {map}
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: `linear-gradient(to bottom, ${color}05, ${color}15)`,
                    }}
                />
            </Box>
            <CardContent sx={{ p: 4, flexGrow: 1 }}>
                <Box sx={{
                    color: color,
                    mb: 2,
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    {icon}
                </Box>
                <Typography variant="h4" component="h2" gutterBottom align="center" sx={{ color: color }}>
                    {title}
                </Typography>
                <Typography variant="body1" color="text.secondary" paragraph align="center">
                    {description}
                </Typography>
                <List sx={{ mb: 3 }}>
                    {benefits.map((benefit, index) => (
                        <ListItem key={index} sx={{ py: 0.5 }}>
                            <ListItemIcon sx={{ minWidth: 36, color: color }}>
                                <CheckCircleOutline fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary={benefit} />
                        </ListItem>
                    ))}
                </List>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        variant="contained"
                        size="large"
                        onClick={onClick}
                        sx={{
                            bgcolor: color,
                            '&:hover': {
                                bgcolor: `${color}dd`,
                            },
                            px: 4
                        }}
                    >
                        Get Started
                    </Button>
                </Box>
            </CardContent>
        </Card>
    );
};

export default LocationCard;