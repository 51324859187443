import { Box, Paper, Typography, Stack } from '@mui/material';
import { Assessment, Pending, CheckCircle, TrendingUp, Visibility, Download } from '@mui/icons-material';

const DashboardIllustration = () => {
  return (
    <Box sx={{ position: 'relative' }}>
      <Paper 
        elevation={3}
        sx={{ 
          bgcolor: '#f8fafc',
          borderRadius: 2,
          overflow: 'hidden',
          height: '360px',
          position: 'relative'
        }}
      >
        {/* Side Nav */}
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            top: 0,
            bottom: 0,
            width: '48px',
            background: 'linear-gradient(135deg, #181aef 0%, #11c88a 100%)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            pt: 2,
            gap: 1
          }}
        >
          {[...Array(4)].map((_, i) => (
            <Box
              key={i}
              sx={{
                width: '32px',
                height: '32px',
                borderRadius: 1,
                bgcolor: 'rgba(255, 255, 255, 0.1)'
              }}
            />
          ))}
        </Box>

        {/* Main Content */}
        <Box sx={{ ml: 6, p: 2 }}>
          {/* Summary Cards */}
          <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
            {[
              { title: 'Total', value: '156', color: '#181aef' },
              { title: 'Pending', value: '23', color: '#f59e0b' },
              { title: 'Completed', value: '133', color: '#11c88a' }
            ].map((item, i) => (
              <Paper
                key={i}
                sx={{
                  p: 1.5,
                  flex: 1,
                  bgcolor: 'white'
                }}
              >
                <Typography variant="body2" color="text.secondary">
                  {item.title}
                </Typography>
                <Typography variant="h5" sx={{ color: item.color }}>
                  {item.value}
                </Typography>
              </Paper>
            ))}
          </Stack>

          {/* Table */}
          <Paper sx={{ bgcolor: 'white' }}>
            <Box sx={{ p: 2, borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="subtitle2">Recent Assessments</Typography>
              <Typography variant="caption" color="text.secondary">
                Last 24 hours
              </Typography>
            </Box>
            {[
              {
                name: 'Compliance Assessment',
                status: 'Completed',
                type: '',
                date: '2h ago',
                isCompliant: true
              },
              {
                name: 'Permit Application',
                status: 'Pending Review',
                type: '',
                date: '4h ago',
                isCompliant: false
              },
              {
                name: 'Label Assessment',
                status: 'In Progress',
                type: '',
                date: '6h ago',
                isCompliant: true
              },
            ].map((row, i) => (
              <Box
                key={i}
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '2fr 1fr 1fr 1fr',
                  gap: 2,
                  p: 1.5,
                  borderBottom: '1px solid #f1f5f9',
                  '&:last-child': { border: 'none' },
                  '&:hover': { bgcolor: '#f8fafc' },
                  alignItems: 'center'
                }}
              >
                <Typography variant="body2" sx={{ fontWeight: 500 }}>
                  {row.name}
                </Typography>
                <Box>
                  <Typography
                    variant="body2"
                    sx={{
                      px: 1,
                      py: 0.5,
                      borderRadius: '50px',
                      fontSize: '0.75rem',
                      bgcolor: row.status === 'In Progress' 
                        ? '#fffbeb'
                        : row.status === 'Completed'
                        ? '#f0fdf4' 
                        : '#fff7ed',
                      color: row.status === 'In Progress'
                        ? '#eab308'
                        : row.status === 'Completed'
                        ? '#11c88a'
                        : '#f59e0b',
                      display: 'inline-block',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    {row.status}
                  </Typography>
                </Box>
                <Typography variant="body2" color="text.secondary">
                  {row.date}
                </Typography>
                <Stack direction="row" spacing={1}>
                  <Visibility 
                    sx={{ 
                      cursor: 'pointer',
                      color: 'primary.main',
                      fontSize: 20,
                      '&:hover': { opacity: 0.8 }
                    }}
                  />
                  <Download 
                    sx={{ 
                      cursor: 'pointer',
                      color: 'primary.main',
                      fontSize: 20,
                      '&:hover': { opacity: 0.8 }
                    }}
                  />
                </Stack>
              </Box>
            ))}
          </Paper>
        </Box>
      </Paper>

      {/* Floating Detail Card */}
      <Paper
        elevation={4}
        sx={{
          position: 'absolute',
          top: '40%',
          right: -80,
          width: 260,
          p: 2,
          borderRadius: 2,
          bgcolor: 'white',
          zIndex: 1,
          transform: 'rotate(2deg)',
          transition: 'transform 0.3s ease-in-out',
          '&:hover': {
            transform: 'rotate(2deg) translateY(-5px)'
          }
        }}
      >
        <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 1 }}>
          <Box
            sx={{
              width: 40,
              height: 40,
              borderRadius: 1,
              bgcolor: '#f0fdf4',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <TrendingUp sx={{ color: '#11c88a' }} />
          </Box>
          <Box>
            <Typography variant="body2" color="text.secondary">
              Risk Score
            </Typography>
            <Typography variant="h6" sx={{ color: '#11c88a' }}>
              Low Risk
            </Typography>
          </Box>
        </Stack>
        <Typography variant="subtitle2" gutterBottom>
          Green Valley Farms
        </Typography>
        <Stack 
          direction="row" 
          justifyContent="space-between" 
          alignItems="center"
          sx={{ mt: 1 }}
        >
          <Typography
            variant="caption"
            sx={{
              px: 1,
              py: 0.5,
              borderRadius: '50px',
              bgcolor: '#f0fdf4',
              color: '#11c88a'
            }}
          >
            Compliant
          </Typography>
          <Typography variant="caption" color="text.secondary">
            Updated 2h ago
          </Typography>
        </Stack>
      </Paper>
    </Box>
  );
};

export default DashboardIllustration;