import {
  Box,
  Container,
  Typography,
  Grid,
  Stack,
  TextField,
  Button,
  Link
} from '@mui/material';
import logoImage from '../assets/logo-horiz.png';

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        bgcolor: '#098a9b',
        color: 'white',
        py: 6,
        mt: 'auto',
        borderTop: 1,
        borderColor: 'divider',
        width: '100%',
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          px: { xs: 2, sm: 3 },
        }}
      >
        <Grid container spacing={{ xs: 3, md: 4 }}>
          <Grid item xs={12} md={4}>
            <Box
              component="img"
              sx={{
                height: 'auto',
                width: 150,
                filter: 'brightness(0) invert(1)',
              }}
              src={logoImage}
              alt="AgriAssure Logo"
            />
            <Typography variant="body2" sx={{ opacity: 0.7, color: 'white' }}>
              Simplifying agricultural compliance management for businesses worldwide.
            </Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <Typography variant="h6" gutterBottom sx={{ color: 'white' }}>
              Product
            </Typography>
            <Stack spacing={2}>
              <Link href="#" color="white" underline="hover">Features</Link>
              <Link href="#" color="white" underline="hover">About Us</Link>
              <Link href="#" color="white" underline="hover">Documentation</Link>
            </Stack>
          </Grid>
          <Grid item xs={12} md={2}>
            <Typography variant="h6" gutterBottom sx={{ color: 'white' }}>
              Company
            </Typography>
            <Stack spacing={2}>
              <Link href="#" color="white" underline="hover">About</Link>
              <Link href="#" color="white" underline="hover">Careers</Link>
              <Link href="#" color="white" underline="hover">Contact</Link>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom sx={{ color: 'white' }}>
              Subscribe to our newsletter
            </Typography>
            <Stack direction="row" spacing={1}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                placeholder="Enter your email"
                sx={{
                  bgcolor: 'white',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'transparent',
                    },
                    '&:hover fieldset': {
                      borderColor: 'transparent',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'transparent',
                    }
                  },
                }}
              />
              <Button 
                variant="contained" 
                sx={{ 
                  bgcolor: 'white', 
                  color: '#098a9b',
                  '&:hover': {
                    bgcolor: 'rgba(255, 255, 255, 0.9)',
                  }
                }}
              >
                Subscribe
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <Typography
          variant="body2"
          sx={{
            mt: 4,
            pt: 4,
            borderTop: 1,
            borderColor: 'rgba(255, 255, 255, 0.2)',
            textAlign: 'center',
            opacity: 0.7,
            color: 'white'
          }}
        >
          © {new Date().getFullYear()} AgriAssure. All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
